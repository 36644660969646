@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');

body {
  margin: 0;
  padding: 0;
  background-color: black;
}

.App {
  text-align: center;
  height: 100vh;
  
}

.App-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  flex-direction: column;
}

.app-name h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 5rem;
  color: white;
  margin: 0;
}
.app-name p {
  text-align: center; 
}

.webcam-container {
  display: flex;
  height: 100%;
  width: 100%;
  position: relative;
}

.webcam-active {
  flex: 1;
  position: relative;
  padding: 20px;
  border-right: 1px solid white;
}



.detected-letters, .segmented-words {
  flex: 1;
  padding: 20px;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  visibility: hidden;
  display: block;
  justify-content: flex-start;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  word-wrap: break-word;
  overflow: auto;
}

.letters-active, .words-active {
  visibility: visible;
}

.webcam-enter,
.webcam-exit,
.canvas-enter,
.canvas-exit,
.detected-letters-enter,
.detected-letters-exit {
  opacity: 1;
}

.webcam-enter-active,
.webcam-exit-active,
.canvas-enter-active,
.canvas-exit-active,
.detected-letters-enter-active,
.detected-letters-exit-active,
.segmented-words-enter-active,
.segmented-words-exit-active {
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
}

.webcam-enter,
.canvas-enter,
.detected-letters-enter,
.segmented-words-enter {
  transform: translateY(-100%);
}

.webcam-exit,
.canvas-exit,
.detected-letters-exit,
.segmented-words-exit {
  transform: translateY(0);
}

.webcam-enter-active,
.canvas-enter-active,
.detected-letters-enter-active,
.segmented-words-enter-active {
  transform: translateY(0);
}

.webcam-exit-active,
.canvas-exit-active,
.detected-letters-exit-active,
.segmented-words-exit-active {
  transform: translateY(100%);
}

.webcam-enter,
.webcam-exit {
  transform: translateX(-100%);
}

.webcam-enter-active,
.webcam-exit-active {
  transform: translateX(0);
}

.webcam-exit-active,
.canvas-exit-active,
.detected-letters-exit-active,
.segmented-words-exit-active {
  opacity: 0.01;
}

/* ...existing styles... */

.get-started-button {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.2rem;
  padding: 10px 20px;
  background-color: #5C6BC0;
  border: none;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
  border-radius: 5px;
  margin-top: 20px;
  font-weight: bold;

}

.get-started-button:hover {
  background-color: #7986CB;
}

.app-description, .mission-statement {
  color: #B3E5FC;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
  max-width: 700px;
  margin: 10px auto;
  line-height: 1.5;
  text-align: center;
  font-weight: bold;
  margin-top: 15%;

}



.developer-credit {
  font-size: 0.8rem;
  color: white;
  text-align: center;
  padding: 10px 0;
  position: absolute;
  width: 100%;
  bottom: 0;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
}

.menu-icon {
  width: 30px;
  height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  position: fixed;
  left: 10px;
  top: 10px;
  z-index: 1000;
}

.menu-icon div {
  width: 100%;
  height: 4px;
  background-color: white;
}

.menu {
  width: 200px;
  background-color: black;
  position: fixed;
  top: 0;
  left: -200px;
  height: 100%;
  overflow: hidden;
  transition: left 0.5s;
  z-index: 999;
  padding-top: 40px;
}
.menu-open {
  left: 0; /* show on-screen when menu is open */
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.menu li {
  margin-bottom: 0px;
}

.menu-link {
  text-decoration: none;
  font-size: 16px;
  color: white;
  display: block;
  padding: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;

}

.menu-link:hover {
  background-color: white;
  color: black;
}

.why-we-do {
  background-color: black;
  color: white;
  padding: 40px;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.5;
}

.why-we-do h1 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center; 
}

.why-we-do p {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 20px auto;
  text-align: justify;
  font-weight: bold;
}

.testing-details {
  padding: 20px;
  font-family: "Montserrat", sans-serif;
  background-color: black;
  border-radius: 10px;
  margin: 20px;
  
}

.testing-details h2 {
  color: white;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;

}

.testing-details p {
  color: white;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;

}

.testing-details ul {
  list-style-type: none;
  padding-left: 20px;
  color: white;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;

}

.activate-button {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  margin: 0px auto;
  padding: 10px 20px;
  background-color: white;
  color: black;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease-in;
}

.activate-button:hover {
  background-color: #5C6BC0;
}

.webcam-active-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* Match the width of the webcam */
  height: 100%; /* Match the height of the webcam */
  z-index: 10; /* Ensure the canvas appears above the webcam */
}

.about-us {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: white;
}



.founder-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
}



.about-us {
  background-color: black;
  color: white;
  padding: 40px;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.5;
}

.about-us h1 {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center; 
}

.bio {
  font-size: 1.2rem;
  max-width: 800px;
  margin: 20px auto;
  text-align: justify;
  font-weight: bold;
}

.fade-enter, .fade-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.fade-enter-active, .fade-exit-done {
  opacity: 1;
  transition: opacity 300ms ease-in-out;
}

@media only screen and (max-width: 1024px) {
  .webcam-container {
    flex-direction: column;
  }
  .webcam-active, .detected-letters, .segmented-words {
    width: 100%;
    border-right: none;
  }
}

@media only screen and (max-width: 768px) {
  .app-name h1 {
    font-size: 3rem;
  }
  .menu {
    width: 150px;
  }
  .menu-icon {
    width: 25px;
    height: 20px;
  }
  .menu-icon div {
    height: 3px;
  }
  .about-us, .why-we-do, .testing-details {
    padding: 20px;
  }
  .bio, .why-we-do p {
    font-size: 1rem;
  }
  .get-started-button {
    padding: 5px 10px;
  }
  .app-description, .mission-statement {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 480px) {
  .app-name h1 {
    font-size: 2.5rem;
  }
  .menu-icon {
    width: 20px;
    height: 18px;
  }
  .menu-icon div {
    height: 2px;
  }
  .menu {
    width: 120px;
  }
  .bio, .why-we-do p {
    font-size: 0.9rem;
  }
  .get-started-button {
    font-size: 1rem;
    padding: 5px;
  }
  .app-description, .mission-statement {
    font-size: 0.9rem;
    max-width: 400px;
  }
}

.developer-link {
  color: white; /* Make the link white */
}

/* If you want to ensure that it stays white even when hovered or clicked: */
.developer-link:hover,
.developer-link:visited,
.developer-link:active {
  color: #add8e6;
  ;
}





