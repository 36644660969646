@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap');

.hand-tracking-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    ;
  }

  .hand-tracking-webcam {
    width: 600px;
    height: 500px;
    z-index: 999;

  }
  
  .hand-tracking-canvas {
    position: absolute;
    top: 0;
    left: 0; 
    width: 600px;
    height: 500px;
    z-index: 999;
  }

  .hand-tracking-info {
    flex: 1;
    padding: 20px;
    color: white;
    font-family: "Montserrat", sans-serif;
    font-size: 1.5rem;
    visibility: visible; /* changed from hidden to visible */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    word-wrap: break-word;
    overflow: auto;
    font-weight: bold;

  }
  
  @media screen and (max-width: 1080px) {
    .hand-tracking-webcam {
      width: 550px;
      height: 500px;
    }
  
    .hand-tracking-canvas {
      position: absolute;
      left: 0;
      top: 0;
      width: 550px;
      height: 500px;
    }
  }   
  @media screen and (max-width: 550px) {

    .hand-tracking-webcam {
        width: 100%;
        height: fit-content;
    }
      
    .hand-tracking-canvas {
        width: 100%;
        height: fit-content;
    }
    .hand-tracking-info {
        font-size: 18px;
        width: 30%;
      }
}


